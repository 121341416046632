import { defineStore } from "pinia";
import {
  callAPI,
  fetchLocation,
  getPaymentIntent,
  fetchSession,
  calculateSessionTotals,
} from "@/src/api";
import { getStripe, getStripeCardElement } from "@/src/stripe";
import type {
  PaymentIntent,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";

export interface APICallBody {
  action: string;
  [k: string]: any;
}

export interface AppState {
  networkIssue: boolean;
  location: {
    locationId: string;
    name: string;
    timezone: string;
    [k: string]: any;
  };
  session: PMC.Parking.Session | null;
  stripe: Stripe | null;
  stripeCard: StripeCardElement | null;
  paymentIntent: PaymentIntent | null;
}

export const useAppStore = defineStore({
  id: "appstore",
  state: (): AppState => ({
    networkIssue: false,
    location: {
      locationId: "",
      name: "",
      timezone: "",
    },
    session: null,
    stripe: null,
    stripeCard: null,
    paymentIntent: null,
  }),
  actions: {
    async setupStripe(stripeKey: string) {
      this.stripe = (await getStripe(stripeKey)) as Stripe;
      this.stripeCard = getStripeCardElement();
    },
    async callAPI(body: APICallBody) {
      return await callAPI(body);
    },
    async fetchLocation(locationId: string) {
      this.location = await fetchLocation(locationId);
      if (this.session) {
        this.session.locationId = this.location.locationId;
        this.session.locationName = this.location.name;
      }
    },
    async fetchSession(sessionId: string) {
      try {
        this.session = await fetchSession(sessionId);
      } catch (err) {
        this.session = null;
      }
    },
    async calculateSessionTotals(sessionId: string) {
      const session = await calculateSessionTotals(sessionId)
        .then((s) => s || this.session)
        .catch(() => this.session);
      this.session = session;
    },
    async getPaymentIntent(amount: number) {
      this.paymentIntent = await getPaymentIntent(
        this.session.sessionId,
        amount,
      );
    },
  },
});

declare global {
  interface Number {
    toInt(): number;
    fromInt(): number;
    countDecimals(): number;
    toCurrency(): string;
  }
}

Number.prototype.toInt = function () {
  const asNumber = Number(this);
  // add 1 to the hundredths to prevent funking JS rounding
  const calculated =
    this.countDecimals() <= 2 ? (asNumber + 0.001) * 100 : asNumber * 100;
  return Math.trunc(calculated);
};

Number.prototype.fromInt = function () {
  const asNumber = Number(this);
  return asNumber / 100;
};

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

Number.prototype.toCurrency = function () {
  const asFloat = Number(this);
  const asCurrency = `$${asFloat}`;
  if (asFloat.countDecimals() === 0) {
    return `${asCurrency}.00`;
  } else if (asFloat.countDecimals() === 1) {
    return `${asCurrency}0`;
  } else if (asFloat.countDecimals() > 2) {
    const split = asCurrency.split(".");
    return `${split[0]}.${split[1].substring(0, 2)}`;
  }
  return asCurrency;
};

export {};

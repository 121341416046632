import axios, { type AxiosResponse } from "axios";
import * as rax from "retry-axios";
import { useAppStore } from "@/stores/app";
const { VITE_API_ID } = import.meta.env;

const client = axios.create({
  baseURL: `https://${VITE_API_ID}.execute-api.us-east-1.amazonaws.com`,
});

let networkIssueTimer: null | ReturnType<typeof setTimeout>;

const clearNetworkIssueTimer = () => {
  if (networkIssueTimer) {
    clearTimeout(networkIssueTimer);
    networkIssueTimer = null;
  }
};

client.interceptors.request.use((config) => {
  const store = useAppStore();

  if (!networkIssueTimer) {
    networkIssueTimer = setTimeout(() => {
      store.networkIssue = true;
    }, 5000);
  }

  return config;
});

// monitor and toggle network issue notificaitons
client.interceptors.response.use(
  (response) => {
    clearNetworkIssueTimer();
    const store = useAppStore();
    store.networkIssue = false;

    return response;
  },
  (error) => {
    clearNetworkIssueTimer();
    const store = useAppStore();

    store.networkIssue =
      error?.code == "ECONNABORTED" ||
      !error?.response ||
      error?.response?.status >= 500;

    return Promise.reject(error);
  },
);

// retry failed axios requests
client.defaults.raxConfig = {
  instance: client,
  retryDelay: 100,
  backoffType: "static",
  shouldRetry: (err) => {
    return (
      err?.code == "ECONNABORTED" ||
      !err?.response ||
      err.response.status >= 500
    );
  },
};
rax.attach(client);

export interface ApiInputs {
  action: string;
}

export interface NewSessionInputs extends ApiInputs {
  locationId: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  vehicleTag: string;
  vehicleState: string;
  rateId: string;
  tosAccepted: boolean;
  startsAt: string;
  duration: number;
  status?: string;
}

export interface GetLocationInputs extends ApiInputs {
  locationId: string;
}

export interface GetParkingSessionInputs extends ApiInputs {
  sessionId: string;
}

export interface GetPaymentIntentInputs extends ApiInputs {
  sessionId: string;
  amount: number;
}

export interface GetVenueInputs extends ApiInputs {
  slug: string;
}

export interface GetVenueEventInputs extends ApiInputs {
  slug: string;
}

// call the api
export const callAPI = async <Input, Response>(
  body: Input,
): Promise<AxiosResponse<Response>> => {
  return await client.post<Response>("/", body);
};

// get a location
export const fetchLocation = async (locationId: string) => {
  const location = await callAPI<
    GetLocationInputs,
    PMC.Common.ApiResponse<ParkingLocationDetailedResponse>
  >({
    action: "getLocation",
    locationId: locationId,
  });
  return location.data.data;
};

// update totals for session
export const calculateSessionTotals = async (sessionId: string) => {
  const session = await callAPI<
    GetParkingSessionInputs,
    PMC.Common.ApiResponse<PMC.Parking.Session>
  >({
    action: "calculateParkingSessionTotals",
    sessionId,
  });
  return session.data.data;
};

// get a session
export const fetchSession = async (sessionId: string) => {
  const session = await callAPI<
    GetParkingSessionInputs,
    PMC.Common.ApiResponse<PMC.Parking.Session>
  >({
    action: "getParkingSession",
    sessionId,
  });
  return session.data.data;
};

// get a payment intent
export const getPaymentIntent = async (sessionId: string, amount: number) => {
  const paymentIntent = await callAPI<GetPaymentIntentInputs, any>({
    action: "getPaymentIntent",
    sessionId,
    amount,
  });
  return paymentIntent.data.data;
};

export const getAPIErrorMessage = (error: any): string => {
  return (
    error?.response?.data?.errors?.[0]?.title ??
    "Unexpected error. Please try again."
  );
};

<template>
  <div id="app">
    <!-- pmc logo -->
    <div class="pmc-logo text-center mb-3">
      <a href="https://parkingmgt.com" target="_blank">
        <img src="/pmc-logo.png" alt="Logo" />
      </a>
    </div>

    <div class="box mb-4" v-if="isLoading">
      <div class="box-header">Loading</div>
      <div class="box-content">
        <div class="box-inner text-center">
          <LoadingState label="Loading your parking session" />
        </div>
      </div>
    </div>

    <router-view v-if="!isLoading" />

    <NetworkUnavailableBanner v-if="store.networkIssue" />
    <FooterLinks />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingState from "@/components/LoadingState.vue";
import NetworkUnavailableBanner from "@/components/NetworkUnavailableBanner.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import { useAppStore } from "@/stores/app";
import moment from "moment/moment";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "MobileView",
  setup() {
    const store = useAppStore();
    const route = useRoute();
    return {
      store,
      sessionId: route.params?.sessionId as string,
    };
  },
  async created(): Promise<void> {
    await this.store.setupStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || "");

    // we're ready to go
    this.isLoading = false;
  },
  components: {
    LoadingState,
    NetworkUnavailableBanner,
    FooterLinks,
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    currentYear() {
      return moment().format("YYYY");
    },
  },
});
</script>

<style lang="scss">
$max-width: 450px;

#app {
  position: relative;
  width: 100vw;
  max-width: $max-width;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #f0f0f0;
}
</style>

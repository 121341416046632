import {
  loadStripe,
  type StripeCardElement,
  type PaymentMethodResult,
  type PaymentRequest,
  type Stripe,
} from "@stripe/stripe-js";
const STRIPE_API_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

// get stripe client
let stripeClient: Stripe;
export const getStripe = async (apiKey: string = STRIPE_API_KEY) => {
  if (!stripeClient) {
    stripeClient = (await loadStripe(apiKey)) as Stripe;
  }
  return stripeClient;
};

// get a card element
export const getStripeCardElement = () => {
  const elements = stripeClient.elements({});
  return elements.create("card", {
    style: {
      base: {},
    },
  });
};

export const getStripePaymentMethodFromCard = async (
  card: StripeCardElement,
): Promise<PaymentMethodResult> => {
  const client = await getStripe();
  return await client.createPaymentMethod({
    type: "card",
    card,
  });
};

// get a payment request object
export const getStripePaymentRequest = (amount: number, label = "Parking") => {
  return stripeClient.paymentRequest({
    country: "US",
    currency: "usd",
    total: {
      label: label,
      amount: amount,
      pending: true,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  });
};

// get a payment button
export const getStripePaymentButton = (paymentRequest: PaymentRequest) => {
  return stripeClient.elements().create("paymentRequestButton", {
    paymentRequest: paymentRequest,
  });
};

export const getPaymentIntent = async (clientSecret: string) => {
  return await stripeClient.retrievePaymentIntent(clientSecret);
};

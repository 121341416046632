<template>
  <div class="network-banner alert alert-danger w-100" role="alert">
    <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
    Weak connection detected
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NetworkUnavailableBanner",
});
</script>

<style scoped>
.network-banner {
  text-align: center;
  font-style: normal;
  font-size: 16px;
}
</style>

<template>
  <div class="footer">
    <div class="mb-2">
      <a href="https://parkingmgt.com" target="_blank">parkingmgt.com</a>
      <span class="ps-1 pe-1">|</span>
      <a href="https://parkingmgt.com/terms-and-conditions/" target="_blank"
        >terms of service</a
      >
      <span class="ps-1 pe-1">|</span>
      <a href="https://parkingmgt.com/privacy-policy/" target="_blank"
        >privacy policy</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterLinks",
});
</script>

import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import { FontAwesomeIcon } from "@/plugins/fontawesome";
import "@/lib/number";
import "@stripe/stripe-js";
import "@/assets/base.scss";

const app = createApp(App);

try {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_STAGE || "dev",
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    // TODO: reduce once we get some data
    // tracesSampleRate: 1.0,
  });
} catch (err) {
  console.error("unable to initialize sentry", err);
}

app.use(router);
app.use(createPinia());
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(VueGtag, {
  config: {
    id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID || "",
  },
  includes: [{ id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID2 || "" }],
});

app.mount("#_app");

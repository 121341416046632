// font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faLocationDot,
  faCircleExclamation,
  faCircleNotch,
  faCar,
  faXmark,
  faClipboardCheck,
  faPersonRunning,
  faPen,
  faTriangleExclamation,
  faSort,
  faSortUp,
  faSortDown,
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
  faStar as faStarFilled,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleQuestion,
  faCircleCheck,
  faStar as faStarEmpty,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faLocationDot,
  faCar,
  faXmark,
  faCircleExclamation,
  faCircleQuestion,
  faCircleCheck,
  faCircleNotch,
  faClipboardCheck,
  faPersonRunning,
  faPen,
  faTriangleExclamation,
  faSort,
  faSortUp,
  faSortDown,
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
  faStarFilled,
  faStarEmpty,
);

export { FontAwesomeIcon, library };

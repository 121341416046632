<template>
  <small>
    <span :style="{ 'padding-right': '5px' }">{{ label }}</span>
    <font-awesome-icon icon="fa-solid fa-circle-notch" spin />
  </small>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "Loading",
    },
  },
});
</script>
